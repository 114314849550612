import React from "react"

import Layout from '../components/layout'
import Head from '../components/head'
import HomePage from "../components/Home Page/homepage"

const IndexPage = () => {
    return (
      <Layout>
          <Head  title="Home" pageDescription="Data driven NFL predictions and ATS picks"/>
          <HomePage/>
      </Layout>
  )
}

export default IndexPage

