import React from "react"
import SiteSection from "./site_section"

import homeStyles from './homepage.module.scss'
import HomeSplash from './splash'
import gamesAsset from '../../assets/nfl-ats-picks/nfl-ats-picks_144.png'
import gamesAssetWebP from '../../assets/nfl-ats-picks/nfl-ats-picks_144.webp'
import gamesAssetMobile from '../../assets/nfl-ats-picks/nfl-ats-picks_125.png'
import gamesAssetMobileWebP from '../../assets/nfl-ats-picks/nfl-ats-picks_125.webp'
import teamAsset from '../../assets/nfl-power-rankings/nfl-power-rankings_200.png'
import teamAssetWebP from '../../assets/nfl-power-rankings/nfl-power-rankings_200.webp'
import teamAssetMobile from '../../assets/nfl-power-rankings/nfl-power-rankings_150.png'
import teamAssetMobileWebP from '../../assets/nfl-power-rankings/nfl-power-rankings_150.png'
import qbAsset from '../../assets/nfl-qb-rankings/nfl-qb-rankings_144.png'
import qbAssetWebP from '../../assets/nfl-qb-rankings/nfl-qb-rankings_144.webp'
import qbAssetMobile from '../../assets/nfl-qb-rankings/nfl-qb-rankings_144.png'
import qbAssetMobileWebP from '../../assets/nfl-qb-rankings/nfl-qb-rankings_144.webp'
import analysisAsset from '../../assets/analysis/analysis_144.png'
import analysisAssetWebP from '../../assets/analysis/analysis_144.webp'
import analysisAssetMobile from '../../assets/analysis/analysis_144.png'
import analysisAssetMobileWebP from '../../assets/analysis/analysis_144.webp'

const HomePage = () => {

    const sections = [
        {
            'sectionName' : 'Picks',
            'sectionTitle' : 'ATS Picks',
            'sectionSubTitle' : 'Projected winners, confidence pool picks, and expected value calculations',
            'sectionLink' : '/games/',
            'sectionAsset' : [gamesAsset, gamesAssetWebP, gamesAssetMobileWebP, gamesAssetMobile]
        },
        {
            'sectionName' : 'Teams',
            'sectionTitle' : 'Power Rankings',
            'sectionSubTitle' : 'Weekly power rankings for every team, including advanced metrics, pythagorean wins, and more',
            'sectionLink' : '/nfl-power-ratings/',
            'sectionAsset' : [teamAsset, teamAssetWebP, teamAssetMobileWebP, teamAssetMobile]
        },
        {
            'sectionName' : 'QBs',
            'sectionTitle' : 'QB Rankings',
            'sectionSubTitle' : 'Model driven rankings of every QB with value against the spread and component level EPA / Play',
            'sectionLink' : '/qb-rankings/',
            'sectionAsset' : [qbAsset, qbAssetWebP, qbAssetMobileWebP, qbAssetMobile]
        },
        {
            'sectionName' : 'Analysis',
            'sectionTitle' : 'Analysis',
            'sectionSubTitle' : 'Deep dives on the research that drives the NFELO model. Explore EPA, Home Field Advantage, and more',
            'sectionLink' : '/analysis/',
            'sectionAsset' : [analysisAsset, analysisAssetWebP, analysisAssetMobileWebP, analysisAssetMobile]
        }
    ]

    return (

        <div className={homeStyles.homeWrapper}>
            <HomeSplash/>
            <div className={homeStyles.sectionWrapper}>
                {sections.map((section) => {
                    return (
                        <SiteSection
                            key={section.sectionName}
                            sectionName={section.sectionName}
                            sectionTitle={section.sectionTitle}
                            sectionSubTitle={section.sectionSubTitle}
                            sectionLink={section.sectionLink}
                            sectionAsset={section.sectionAsset}
                        />
                    )
                })}
            </div>
        </div>

    )

}

export default HomePage