import React from "react"

import splashStyles from './splash.module.scss'
import headlineImage from '../../assets/nfelostack/nfelostack_482.png'
import headlineImage_webp from '../../assets/nfelostack/nfelostack_482.webp'
import headlineImage_mobile from '../../assets/nfelostack/nfelostack_264.png'
import headlineImage_mobile_webp from '../../assets/nfelostack/nfelostack_264.webp'
import ResponsiveImg from "../Images/responsive_image"

const HomeSplash = () => {

    return (

        <div className={splashStyles.splashWrapper}>
            <div className={splashStyles.headlineDiv}>
                <h1 className={splashStyles.headline}>
                    Data-driven Analytics, Picks, and Predictions for the NFL
                </h1>
                <h2 className={splashStyles.subtitle}>
                    Using play-by-play data, player grades, and market information to power some of the interenet's most accurate picks and power rankings
                </h2>
            </div>
            <div className={splashStyles.headlineImageDiv}>
                <ResponsiveImg
                    desktopWebp={headlineImage_webp}
                    mobileWebp={headlineImage_mobile_webp}
                    mobilePng={headlineImage_mobile}
                    imgMain={<img
                        className={splashStyles.headlineImage}
                        src={headlineImage}
                        alt={'nfelo data stack'}
                    ></img>}
                />
            </div>
        </div>

    )

}

export default HomeSplash