import { Link } from "gatsby"
import React from "react"
import ResponsiveImg from "../Images/responsive_image"

import secStyles from './site_section.module.scss'



const SiteSection = ({ sectionName, sectionTitle, sectionSubTitle, sectionLink, sectionAsset}) => {

    return (

        <div className={secStyles.sectionWrapper} key={sectionName}>
            <div className={secStyles.assetContainer}>
                <ResponsiveImg
                    desktopWebp={sectionAsset[1]}
                    mobileWebp={sectionAsset[2]}
                    mobilePng={sectionAsset[3]}
                    imgMain={<img
                        className={secStyles.asset}
                        src={sectionAsset[0]}
                        alt={sectionName}
                    ></img>}
                />
            </div>
            <div className={secStyles.titleDiv}>
                <h3 className={secStyles.title}>
                    {sectionTitle}
                </h3>
            </div>
            <div className={secStyles.subTitleDiv}>
                <p className={secStyles.subTitle}>
                    {sectionSubTitle}
                </p>
            </div>
            <div className={secStyles.buttonDiv}>
                <Link
                    className={secStyles.buttonLink}
                    to={sectionLink}
                >
                    <p className={secStyles.buttonText}>
                        {`${sectionName} \u279C`}
                    </p>
                </Link>
            </div>
        </div>

    )

}

export default SiteSection