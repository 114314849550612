import React from "react"

const ResponsiveImg = ({
    desktopWebp,
    mobileWebp,
    mobilePng,
    imgMain
}) => {

    const mobileBreak = '640px'

    return (
        <picture>
            <source srcSet={desktopWebp} media={`(max-width: ${mobileBreak})`} type='image/webp'/>
            <source srcSet={mobileWebp} type='image/webp'/>
            <source srcSet={mobilePng} type='image/png'/>
            {imgMain}
        </picture>
    )

}

export default ResponsiveImg